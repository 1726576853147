import * as Sentry from "@sentry/browser";
const API_URL = import.meta.env.VITE_API_URL;
import { getStorage, setStorage } from "./utils";
import { getJWT } from "./auth";

export async function newSession() {
  const jwt = await getJWT();
  const mode = getStorage("mode");
  try {
    const response = await fetch(`${API_URL}/reset?mode=${mode}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    });
    const data = await response.json();
    setStorage("session_id", data.session_id);
    return data;
  } catch (err) {
    console.log(err);
    alert("possible error try refreshing the page");
  }
}

export function checkSession() {
  const session_id = getStorage("session_id");
  if (!session_id) {
    newSession();
  }
  console.log(session_id)
}

export async function sendCommand(command: string, endpoint: string) {
  const jwt = await getJWT();
  const session_id = getStorage("session_id");
  if (jwt && session_id) {
    return fetch(`${API_URL}/${endpoint}`, {
      method: "POST",
      body: JSON.stringify({
        command,
        session_id,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwt}`,
      },
    })
      .then((res) => {
        const reader = res.body
          ?.pipeThrough(new TextDecoderStream())
          .getReader()!;
        return reader;
      })
      .catch((err) => {
        Sentry.captureException(err);
        console.error(err);
        alert("Something went wrong - we recommend refreshing the page");
      });
  } else {
    Sentry.captureException({ jwt, session_id });
    alert("possible error try refreshing the page");
  }
}

export async function manual(command: string) {
  return sendCommand(command, "manual");
}

export async function constructor(command: string) {
  return sendCommand(command, "constructor");
}

export async function auto() {
  const jwt = await getJWT();
  const session_id = getStorage("session_id");
  // TODO implement auto for constructor
  if (jwt && session_id) {
    return fetch(`${API_URL}/auto`, {
      method: "POST",
      body: JSON.stringify({
        session_id,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwt}`,
      },
    })
      .then((res) => {
        const reader = res.body
          ?.pipeThrough(new TextDecoderStream())
          .getReader()!;
        return reader;
      })
      .catch((err) => {
        Sentry.captureException(err);
        console.error(err);
        alert("Something went wrong - we recommend refreshing the page");
      });
  } else {
    Sentry.captureException({ jwt, session_id });
    alert("possible error try refreshing the page");
  }
}

interface Message {
  id: string;
  content: string;
  created_at: string;
  is_user: boolean;
}

export interface SessionData {
  session_id: string;
  messages: Message[];
}

export async function getSessionMessages(sessionId?: string) {
  const jwt = await getJWT();

  if (jwt) {
    const url = new URL(`${API_URL}/session`);
    if (sessionId) {
      url.searchParams.append("session_id", sessionId);
    }

    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${jwt}`,
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data: SessionData = await response.json();
      return data;
    } catch (err) {
      Sentry.captureException(err);
      console.error("Failed to fetch session messages:", err);
      alert("Failed to fetch session messages. Please try again.");
    }
  } else {
    Sentry.captureException({ jwt });
    alert("Authentication error. Please try refreshing the page.");
  }
}

interface Session {
  created_at: string;
  id: string;
  is_active: boolean;
  metadata: Metadata;
  user_id: string;
}

interface Metadata {
  metadata: Record<string, string>;
}

export async function getSessions() {
  const jwt = await getJWT();

  const mode = getStorage("mode");

  if (jwt) {
    const url = new URL(`${API_URL}/sessions?mode=${mode}`);

    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${jwt}`,
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data: Session[] = await response.json();

      // console.trace(data);
      return data;
    } catch (err) {
      Sentry.captureException(err);
      console.error("Failed to fetch sessions:", err);
      alert("Failed to fetch sessions. Please try again.");
    }
  }
}

export async function updateSessionMetadata(metadata: Record<string, any>) {
  const jwt = await getJWT();
  const sessionId = getStorage("session_id");
  const currentMode = getStorage("mode");

  if (!sessionId) {
    console.error("No session ID found in local storage");
    alert("No active session found. Please start a new session.");
    return;
  }

  const updatedMetadata = {
    ...metadata,
    mode: currentMode,
  };

  if (jwt) {
    const url = new URL(`${API_URL}/sessions/${sessionId}/metadata`);

    try {
      const response = await fetch(url, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${jwt}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(updatedMetadata),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const updatedSession: Session = await response.json();
      console.log("Session metadata updated:", updatedSession);
      return updatedSession;
    } catch (err) {
      Sentry.captureException(err);
      throw err;
      // console.error("Failed to update session metadata:", err);
      // alert("Failed to update session metadata. Please try resetting the conversation");
    }
  } else {
    Sentry.captureException({ jwt });
    alert("Authentication error. Please try refreshing the page.");
  }
}

export async function getShareCode() {
  const jwt = await getJWT();
  const sessionId = getStorage("session_id");

  if (!sessionId) {
    console.error("No session ID found in local storage");
    alert("No active session found. Please start a new session.");
    return;
  }
  if (jwt) {
    const url = new URL(`${API_URL}/share/${sessionId}`);
    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${jwt}`,
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const { code } = await response.json();
      return code;
    } catch (err) {
      Sentry.captureException(err);
      console.error("Failed to generate share link:", err);
      alert("Failed to generate share link. Please try again.");
    }
  }
}

export async function getSharedMessages(code: string) {
  const url = new URL(`${API_URL}/share/messages/${code}`);

  try {
    const response = await fetch(url, {
      method: "GET",
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data: SessionData = await response.json();
    return data;
  } catch (err) {
    Sentry.captureException(err);
    console.error("Failed to fetch session messages:", err);
    alert("Failed to fetch session messages. Please try again.");
  }
}

export async function getSummary() {
  const jwt = await getJWT();
  const url = new URL(`${API_URL}/summary`);
  const sessionId = getStorage("session_id");
  if (!sessionId) {
    console.error("No session ID found in local storage");
    alert("No active session found. Please start a new session.");
    return;
  } else {
    url.searchParams.append("session_id", sessionId);
  }
  if (jwt) {
    // const url = new URL(`${API_URL}/summary`);

    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${jwt}`,
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();

      return data;
    } catch (err) {
      Sentry.captureException(err);
      console.error("Failed to fetch summaries:", err);
      alert("Failed to fetch summaries. Please try again.");
    }
  }
}

export async function getIdentity(messageId: string, metamessageId: string) {
  const jwt = await getJWT();
  const sessionId = getStorage("session_id");
  const url = new URL(`${API_URL}/identity?session_id=${sessionId}&message_id=${messageId}&metamessage_id=${metamessageId}`);
  const response = await fetch(url, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${jwt}`,
      "Content-Type": "application/json",
    },
  });
  const data = await response.json();
  return data;
}

export async function exportSession() {
  const jwt = await getJWT();
  const sessionId = getStorage("session_id");

  if (!sessionId) {
    console.error("No session ID found in local storage");
    alert("No active session found. Please start a new session.");
    return;
  }

  if (jwt) {
    try {
      const response = await fetch(new URL(`${API_URL}/export/${sessionId}`), {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      const blob = new Blob([JSON.stringify(data, null, 2)], { type: "application/json" });
      const url = window.URL.createObjectURL(blob);
      const timestamp = new Date().toISOString().replace(/[:.]/g, "-");
      const a = document.createElement("a");
      a.href = url;
      a.download = `yousim_conversation_${timestamp}.json`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
      return true;
    } catch (err) {
      Sentry.captureException(err);
      console.error("Failed to export session:", err);
      alert("Failed to export session. Please try again.");
      return false;
    }
  }
}

export async function chat(sessionId: string, userInput: string) {
  const jwt = await getJWT();
  const originalSessionId = getStorage("chat_original_session_id");
  const summaryId = getStorage("chat_summary_id");
  const summaryMessageId = getStorage("chat_summary_message_id");
  const identityStr = getStorage("identity");
  const prompt = identityStr ? JSON.parse(identityStr) : null;
  console.log("Chat request parameters:", {
    sessionId,
    originalSessionId,
    summaryId,
    summaryMessageId,
    userInput,
    prompt
  });

  if (!originalSessionId || !summaryId || !summaryMessageId) {
    console.error("Missing required chat parameters:", {
      originalSessionId,
      summaryId,
      summaryMessageId
    });
    throw new Error("Missing required chat parameters");
  }

  if (jwt && sessionId) {
    try {
      const response = await fetch(`${API_URL}/chat`, {
        method: "POST",
        body: JSON.stringify({
          session_id: sessionId,
          command: userInput,
          original_session_id: originalSessionId,
          summary_id: summaryId,
          summary_message_id: summaryMessageId,
          prompt: prompt
        }),
        headers: {

          "Content-Type": "application/json",
          Authorization: `Bearer ${jwt}`,
        },
      });

      console.log("Chat API response status:", response.status);
      
      if (!response.ok) {
        const errorText = await response.text();
        console.error("Chat API error:", {
          status: response.status,
          statusText: response.statusText,
          body: errorText
        });
        throw new Error(`HTTP error! status: ${response.status} - ${errorText}`);
      }

      if (!response.body) {
        throw new Error("No response body received from chat API");
      }

      const reader = response.body.pipeThrough(new TextDecoderStream()).getReader();
      return reader;
    } catch (err) {
      console.error("Chat API call failed:", err);
      Sentry.captureException(err);
      throw err;
    }
  } else {
    const error = new Error("Missing JWT or session ID");
    console.error("Chat prerequisites missing:", { jwt: !!jwt, sessionId });
    Sentry.captureException({ jwt, sessionId });
    throw error;
  }
}
